


























































































































import {
  Component, Prop, Vue,
} from 'vue-property-decorator';
import EditCustomGoalNewPlanViewModel
  from '@/vue-app/view-models/components/goals-dashboard/edit-plan-goals/edit-custom-goal-plan/edit-custom-goal-new-plan-view-model';

@Component({ name: 'EditCustomGoalNewPlan' })
export default class EditCustomGoalNewPlan extends Vue {
  @Prop({ type: String, required: true })
  customGoalName!: string;

  edit_custom_goal_new_plan_view_model = Vue.observable(
    new EditCustomGoalNewPlanViewModel(this),
  );

  async mounted() {
    await this.edit_custom_goal_new_plan_view_model.initialize();
  }
}
